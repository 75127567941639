














































































import { Component, Vue } from 'vue-property-decorator';
import user from "@/store/user";
import InputValidator from "@/helper/inputValidator";

@Component
export default class SignUpForm extends Vue {

  private email = "";
  private password = "";
  private confirmPassword = "";

  private error = false;
  private invalidEmail = false;
  private invalidPassword = false;
  private invalidConfirmPassword = false;

  private showPassword = false;
  private showConfirmPassword = false;

  private signUpCompleted = false;

  goTo(path: string): void {
    this.$router.push(path);
  }

  toggleShowPassword(confirm: boolean): void {
      if (confirm) {
          this.showConfirmPassword = !this.showConfirmPassword;
      } else {
          this.showPassword = !this.showPassword;
      }
  }

  signup(): void {
    this.invalidEmail = !InputValidator.checkInvalidEmail(this.email);
    this.invalidPassword = !InputValidator.checkPassword(this.password);
    this.invalidConfirmPassword = !InputValidator.checkConfirmPassword(this.password, this.confirmPassword);

    if (this.invalidEmail || this.invalidPassword || this.invalidConfirmPassword) {
        return;
    }

    user.dispatchSignIn({ email: this.email, password: this.password }).then((r: boolean) => {
      if (r) {
        this.signUpCompleted = true;
      } else {
        this.error = true;
      }
    });
  }

  get canSignup(): boolean {
      return !!this.email && !!this.password && !!this.confirmPassword;
  }

}
